import React, { useEffect, useState } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ApolloProvider, useQuery, useReactiveVar } from '@apollo/client';
import { GraphQlClient } from 'graphql/base';
import { GET_MY_CUSTOM_PRODUCT_CONFIG } from 'graphql/customer/custom-products';
import { useAccount } from '@silkpwa/module/account';
import { InitCustomerSession } from 'ui/page/checkout-page/checkout-state';
import { MainColumn } from 'ui/component/main-column';
import { classes } from '@silkpwa/module/util/classes';
import { DocumentTitle } from '@silkpwa/module/react-component/document-title';
import { LeftSidebar } from 'ui/page/sales-pages/layouts/account-layout/left-sidebar';
import { myCustomProductsConfig } from 'ui/page/customer-pages/layouts/my-custom-products-layout-state';
import styles from './style.css';

interface IMyCustomProductsLayoutProps {
    children: React.ReactNode;
    showSidebar?: boolean;
}

declare global {
    interface Window {
        isSessionInitialized?: boolean;
    }
}

window.isSessionInitialized = false;

export const MyCustomProductsLayout: React.FC<IMyCustomProductsLayoutProps> = ({ children, showSidebar }) => {
    const t = usePhraseTranslater();
    const { isLoggedIn } = useAccount();
    const [configLoaded, setConfigLoaded] = useState(false);
    const config = useReactiveVar(myCustomProductsConfig);
    const { data } = useQuery(GET_MY_CUSTOM_PRODUCT_CONFIG);
    const initializeSession = async () => {
        await InitCustomerSession(false, true);
    };
    useEffect(() => {
        if (window.isSessionInitialized === true) {
            return;
        }
        initializeSession().then(() => {
            window.isSessionInitialized = true;
        });
    }, [isLoggedIn]);
    useEffect(() => {
        if (data?.getMyProductConfig?.enabled) {
            myCustomProductsConfig(data.getMyProductConfig);
            setConfigLoaded(true);
        }
    }, [data]);
    return (
        <MainColumn className={styles.myCustomProductsLayout}>
            <DocumentTitle>{t('My Custom Products')}</DocumentTitle>
            <ApolloProvider client={GraphQlClient}>
                { showSidebar && <LeftSidebar /> }
                { configLoaded && config?.enabled && (
                    <div className={classes(styles.layoutContent, { [styles.fullWidth]: !showSidebar })}>
                        <div className={styles.mainContent}>
                            {children}
                        </div>
                    </div>
                )}
            </ApolloProvider>
        </MainColumn>
    );
};
