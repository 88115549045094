/* eslint-disable camelcase */
import { makeVar } from '@apollo/client';

interface IMyCustomProductConfig {
    enabled: boolean;
    default_page_limit: number;
    products_per_page_values: number[];
    exclude_option_ids: number[];
    delay_message: string;
    enable_mockup_download: boolean;
}

export const myCustomProductsConfig = makeVar<IMyCustomProductConfig|null>(null);
