import { gql } from '@apollo/client';

export const MY_PRODUCT_FIELDS = gql`
    fragment MyProductFields on MyProduct {
        entity_id
        item_id
        embroidery_data {
          original_product_id
          emb_color
          emb_color_price
          emb_color_size {
            color {
              available
              id
              price
              sku
              title
            }
            size {
              available
              id
              price
              sku
              title
            }
          }
          emb_digitization_price
          emb_flag
          emb_flag_price
          emb_image {
            caption
            facet
            img
            isMain
            large
            position
            thumb
          }
          emb_logo
          emb_logo_price
          emb_si
          emb_size
          emb_size_price
          emb_text
          emb_text_key_value {
            option_code
            option_id
            option_value
          }
          emb_text_price
          emb_selections {
            option_id
            option_value
          }
          emb_options
          image_delay_text
        }
        product_description
        product_name
        sku
    }
`;
