/* eslint-disable camelcase */
import { gql, TypedDocumentNode } from '@apollo/client';
import { MY_PRODUCT_FIELDS } from './fragments/custom-product-fields';

interface IEmbImage {
    caption: string;
    facet: string;
    img: string;
    isMain: boolean;
    large: string;
    position: number;
    thumb: string;
}

interface IEavAttribute {
    option_id: string;
    option_value: string;
}

interface ITextKeyValue extends IEavAttribute {
    option_code: string;
}

interface IColorSize {
    available: boolean;
    id: number;
    price: number;
    sku: string;
    title: string;
}

export interface IEmbroideryData {
    original_product_id: number;
    emb_color: string;
    emb_color_price: number;
    emb_color_size: {
        color: IColorSize[];
        size: IColorSize[];
    };
    emb_digitization_price: number;
    emb_flag: string;
    emb_flag_price: number;
    emb_image: IEmbImage[];
    emb_logo: string;
    emb_logo_price: number;
    emb_si: string;
    emb_size: string;
    emb_size_price: number;
    emb_text: string;
    emb_text_key_value: ITextKeyValue[];
    emb_text_price: number;
    emb_selections: IEavAttribute[];
    emb_options: string;
    image_delay_text: string;
}

export interface IMyCustomProduct {
    entity_id: number;
    item_id: number;
    embroidery_data: IEmbroideryData;
    product_description: string;
    product_name: string;
    sku: string;
}

export interface IMyCustomProductsOutput {
    getMyProducts: {
        total_count: number;
        items: IMyCustomProduct[];
    };
}

interface IMyCustomProductOutput {
    getMyProduct: IMyCustomProduct;
}

interface IMyCustomProductConfigOutput {
    getMyProductConfig: {
        enabled: boolean;
        default_page_limit: number;
        products_per_page_values: number[];
        exclude_option_ids: number[];
        delay_message: string;
        enable_mockup_download: boolean;
    };
}

interface IMyCustomProductConfigInput {
}

interface IMyCustomProductsInput{
}

interface IMyCustomProductInput {
    id: string;
}

interface ISaveMyCustomProduct {
    entity_id: number;
    item_id: number;
    sku: string;
    product_name: string;
    product_description: string;
    emb_data: string;
}

interface ISaveMyCustomProductInput {
    data: ISaveMyCustomProduct;
}

export const GET_MY_CUSTOM_PRODUCT_CONFIG: TypedDocumentNode<IMyCustomProductConfigOutput, IMyCustomProductConfigInput> = gql`
    query getMyProductConfig {
      getMyProductConfig {
        enabled
        default_page_limit
        products_per_page_values
        exclude_option_ids
        delay_message
        enable_mockup_download
      }
    }
`;

export const GET_MY_CUSTOM_PRODUCTS: TypedDocumentNode<IMyCustomProductsOutput, IMyCustomProductsInput> = gql`
    ${MY_PRODUCT_FIELDS}
    query getMyProducts(
        $page: Int = 1
        $pageSize: Int = 12
    ) {
      getMyProducts(
        page: $page
        page_size: $pageSize
      ) {
        total_count
        items {
            ...MyProductFields
        }
      }
    }
`;

export const GET_MY_CUSTOM_PRODUCT: TypedDocumentNode<IMyCustomProductOutput, IMyCustomProductInput> = gql`
    ${MY_PRODUCT_FIELDS}
    query getMyProduct($id: Int!){
      getMyProduct(
        id: $id
      ) {
        ...MyProductFields
      }
    }
`;

export const SAVE_MY_CUSTOM_PRODUCT: TypedDocumentNode<IMyCustomProductOutput, ISaveMyCustomProductInput> = gql`
    ${MY_PRODUCT_FIELDS}
    mutation saveMyProduct(
        $data: SaveMyProductInput!
    ) {
      saveMyProduct(
        input: $data
      ) {
        ...MyProductFields
      }
    }
`;
